import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import iconBG from "../../../assets/images/blog/icon-bg-min.jpg"
import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import bulgaria from "../../../assets/images/blog/bulgaria-min.jpg"

export default () => 
<div>
    <SEO title={'The Future of Nearshore Development | ICON Worldwide Bulgaria '} 
    description='We are proud to introduce ICON Bulgaria, our future-oriented nearshore development solution for enterprises and agencies. Discover our services now! ' 
    canonical={'https://icon-worldwide.com/blog/icon-bulgaria-nearshore-development'}/>

    <NewsHeader/>

    <div id="single-news">
    <img src={iconBG} alt="insights" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
        <h1>The Future of Nearshore Development is <span>Built Here</span> [let's introduce ICON Bulgaria!]</h1>
        <div id="title-underline"></div>
        <h2>In January, 2018 ICON Worldwide opened a dedicated Development Center in Sofia, Bulgaria designed to meet the growing demand for technology talent.</h2>

        <div id="single-news-texts">
            <p>The technology industry is evolving rapidly. Demand for skilled developers is high and talent is in short supply. Simultaneously, our clients are demanding we solve more complex development projects.</p>
            <p>For over ten years ICON Worldwide has partnered with multinational companies to develop everything from websites and <a href="https://www.designrush.com/agency/html5-mobile-app-development-agencies" target="_blank" rel="noopener noreferrer">mobile applications</a>, to complex medical diagnostic devices and software products.  Often, we serve as outsourced IT partners, hosting and running businesses.</p>
            <p>Bulgaria, with its excellent technical universities, strong IT work force and digital infrastructure will allow us to scale rapidly to meet the demands of our customers.</p>
            <h2>Blockchain, Artificial Intelligence & Machine Learning. 85% of companies think AI will offer a competitive advantage</h2>
            <p>As technologies like Blockchain, AI and Machine Learning begin to realise their potential as major business initiatives, companies will be challenged to find the developers needed to execute and manage these projects internally.</p>
            <p>According to a report by MIT Sloan Management Review and the Boston Consulting Group, around 85% of companies think AI will offer a competitive advantage, but only 1 in 20 is extensively employing it today. This points to even more demand in the future for developers capable of working in these new areas, as well as the managers to lead these teams.</p>
            <h2>AI Merger & Acquisitions are up 26%</h2>
            <p>Larger companies may try to meet this demand via acquisition.  In 2017 firms worldwide spent $21.8 billion on AI related acquisitions, up 26% from 2015. For mid-size companies, for whom acquisition is not an answer, the ability to implement these new technologies is daunting.  Though technologies such as Blockchain may be early stage, the indication is that many companies large and small are already thinking about how to apply this to their business.  Very quickly the theory will turn to the practical of how to build their Blockchain solution.  We now stand ready to support our customers to maximise these exciting opportunities.</p>
            <p><a  rel="noopener noreferrer canonical" target="_blank" rel="noopener noreferrer" href="https://www.economist.com/special-report/2018/03/28/non-tech-businesses-are-beginning-to-use-artificial-intelligence-at-scale">Economist: The growth of AI</a></p>
            <h2>ICON Worldwide Development: Platform Agnostic</h2>
            <img src={bulgaria} style={{width:'100%', margin:'0 auto'}} alt="Bulgaria high reputation on StackOverflow, perfect choice in terms of nearshore development" title="ICON Bulgaria Nearshore Development"/>
            <p>At ICON Worldwide, our in-house, Zurich team is building a wide range of projects for clients around the world, including: Switzerland, Germany, Spain, the United States, and the Middle East.</p>
            <p>We pride ourselves on being “Platform Agnostic”, this means our developers are able to program across all technologies. This core philosophy, unusual amongst development firms, make us particularly well suited to face the growing number of new technologies arising.</p>
            <h2>Dedicated Strategy, Design and Development Teams</h2>
            <p>We believe in putting developers at the table with clients throughout the entire scope of a project. Our clients have, like everyone else, become sophisticated in their knowledge of technology and experience running technology projects.  Likewise, we believe that designers and developers complement each other in the process of any project.</p>
            <p>Our aim is to extend this model to meet the future of technology together with our clients. Please contact us to learn more or visit us in Zürich or Sofia.</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>
        <NewsFooter previous="how-to-lead-ai-iot-blockchain-projects" next="applying-ai-technologies-to-rare-disease-diagnosis"/>
    </div>

    <Footer noScrollbar="true"/>
</div>